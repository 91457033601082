import React, { useEffect, useState } from "react";
import axios from "axios";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

const PrivacyPolicy = () => {
  const [privacyPolicy, setPrivacyPolicy] = useState("");

  useEffect(() => {
    fetchPrivacyPolicy();
  }, []);

  const fetchPrivacyPolicy = async () => {
    const {data} = await axios
      .get("https://www.thebeingabroad.com/api/v1/auth/termsAndConditions", {
        headers: {
          withCredentials: false,
          "Access-Control-Allow-Origin": "*",
        },
      })

      if(data) {
        setPrivacyPolicy(data.privacyPolicy);
      }
      // .then((res) => {
      //   setPrivacyPolicy(res.data?.privacyPolicy);
      // });
  };

  return (
    <>
      <Navbar />
      <div style={{ margin: "3rem 1rem 3rem 1rem" }}>
        {/* <span dangerouslySetInnerHTML={{ __html: privacyPolicy }} /> */}
        {privacyPolicy?.split("\n").map((line, index) => (
          <React.Fragment key={index}>
            {line}
            <br />
          </React.Fragment>
        ))}
      </div>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
